<template>
  <div class="user-icon-container">
    <el-avatar style="vertical-align: middle"
               :style="{
      'box-shadow': disableAvatarBorder ? null : 'inset 0 0 0 1px var(--gray-3)'
               }"
               ref="avatar" :src="(!url || url.endsWith('avt.jpg')) ? null : url"   @error="()=>true" :size="icon_size">
      <!--              <img src="static/img/loadImgError.png"/>-->
      <div  :style="{
        'background-image':`linear-gradient(${userAvatarBackgroundColor},${userAvatarBackgroundColor})`,
        'box-shadow': disableAvatarBorder ? null : 'inset 0 0 0 1px var(--gray-2)'
      }" class="stage-order ">
        <p v-text-resolve class="user-name-text">
          <span class="insetCenter">
              {{(getFirstName(name) + '').toUpperCase()}}
          </span>
        </p>
      </div>
    </el-avatar>
    <div class="top-left-corner">
      <div class="email-badge" v-if="subIcon=='email'">
        <i class="material-icons" style="font-size: 14px;color:var(--font-gray)">mail</i>
      </div>
      <div class="approval-badge pass" v-else-if="subIcon=='check'">
        <i class="material-icons">check</i>
      </div>
      <div class="approval-badge refuse" v-else-if="subIcon=='close'">
        <i class="material-icons">close</i>
      </div>
    </div>
  </div>

</template>

<script>
import {colorLog, getHashColor, getUserName} from "../util";

export default {
  name: "UserIcon",
  props:["name","url","uid","size","subIcon","count","disableAvatarBorder"],
  mounted() {
    if(this.size){
      this.icon_size=this.size
    }
  },
  data(){
    return{
      userAvatarBackgroundColor:getHashColor(this.uid),
      icon_size:"small",
      length:this.count?this.count:1
    }

  },
  methods:{
    getFirstName(){
      if(this.name && this.name.length>0) {
        if(this.count&&this.name.length>this.count){
          return "···"
        }
        return getUserName(this.name).substr(0,this.length)
      }
      return ""
    }
  },
  directives:{
    textResolve:{
      mounted(el,binding,vnode) {
        binding.instance.$nextTick(()=>{
          let size = binding.instance.$refs.avatar.size;
          if(!isNaN(size)){
            el.style.fontSize = parseInt(size) * .5 + 'px';
          }
        })


      }
    }
  }
}
</script>

<style scoped>
.user-icon-container{
  position: relative;
  flex-shrink: 0;
}
.stage-order {
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 50%;
  color: white;
  overflow: hidden;


  flex-shrink: 0;


}
/*p.user-name-text::before {*/
/*  content: '';*/
/*  width: 0;*/
/*  height: 100%;*/
/*  vertical-align: middle;*/
/*  display: inline-block;*/
/*}*/
.user-name-text>span{
  display: inline-block;
  word-break: normal;
}
.el-avatar{
  background:white !important;
}
.el-avatar--small >>> img{
  width: 100% !important;
  height: 100% !important;
  line-height: calc(var(--userIconSize) - 2px) !important;
}
.user-name-text{
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}
.top-left-corner{
  position: absolute;
  left: 0px;
  top:0px;
}
.insetCenter{
  cursor: default;
}
.email-badge {
  background: transparent;
  position: absolute;
  left: -5px;
  top: -5px;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  text-align: center;
  /*box-shadow: rgb(60 64 67 / 15%) 1px 1px 3px 1px;*/
  color: white;
}

.approval-badge{

  left: -3px;
  top: -3px;

  border-radius: 8px;
  height: 16px;
  width: 16px;
  line-height: 18px;
  text-align: center;
  color: white;
  position: relative;

}

.pass{
  background: #6dce46;
}

.refuse{
  background: #ff4646;
}

.approval-badge i{
  font-size: 10px;
}
</style>
